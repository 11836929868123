<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false"></nav-form>
    <section>
      <article style="height:395px">
        <field
          name="ndoc"
          widget="char"
          searchable
          label="Nº Devolución"
          width="110px"
          style="top:5px; left:5px;"
        />
        <field name="fecha" type="date" label="Fecha" width="100px" style="top:5px; left:130px;" />
        <field
          autofocus
          ref="cliente_id"
          name="cliente_id"
          widget="m2o"
          label="Cliente"
          searchable
          width="320px"
          style="top:5px; right:55px;"
          labelProp="nombre_comercial"
          imgProp="imagen.imagen"
          :fields="[
            'nacionalidad',
            {name:'divisa_id',fields:['codigo','nombre','ratio']},
            'tipo_impuesto',
            'iva',
            're',
            'descuento_especial',
            'financiacion',
            {name:'bancos',fields:['predet','titular','cuenta','swift']},
            {name:'direcciones',fields:['predet','descripcion','responsable','direccion','direccion1','cp','poblacion',{name:'provincia',fields:['codigo','nombre']},{name:'pais',fields:['codigo','nombre']},'telefono','telefono1','movil','movil1','fax']},
            'margen',
            {name:'representante_id',fields:['codigo','imagen','nombre', {name:'almacen', fields:['codigo','nombre']}]},
            'tarifa',
            {name:'comentarios',fields:['descripcion','fecha','activo']},
            {name:'forma_pago_id',fields:['codigo','descripcion','vencimientos','periodicidad','periodicidad_unidades']},
            'descuento_linea_unidad',
            'descuento_linea_peso']"
        />
        <r-tabs
          ref="main-tabs"
          style="top:40px;height:353px;"
          :wt="formData.wt"
          :buttons="[
            'Cabecera',
            'Líneas'
          ]"
          :classButton="{disabled:!formData.cliente_id}"
        >
          <div class="tab">
            <field
              name="total_unidades"
              widget="float"
              type="number"
              dec="2"
              label="Total Unidades"
              inline-label
              width="80px"
              style="top:10px; left:14px;"
              :readonly="true"
            />
            <field
              name="total_peso"
              widget="float"
              type="number"
              dec="3"
              label="Total Peso"
              inline-label
              width="80px"
              style="top:10px; left:195px;"
              :readonly="true"
            />
            <field
              name="total_articulos"
              widget="float"
              type="number"
              dec="2"
              label="Total Artículos"
              inline-label
              width="80px"
              style="top:10px; left:350px;"
              :readonly="true"
            />
            <field
              name="adjuntos"
              :max="6"
              widget="files"
              label="Adjuntos"
              style="top:168px; left:11px;"
            />
            <field
              name="observaciones"
              widget="text"
              label="Observaciones"
              width="429px"
              height="40px"
              style="top:168px; right:11px;"
            />
          </div>
          <div class="tab">
            <field
              ref="lineas"
              name="lineas"
              class="lineas"
              :htSettings="{rowHeights:15}"
              widget="handsontable"
              :height="303"
              :minRows="18"
              style="top:5px; left:4px; width: 648px;"
              customprop="lineas_customprop"
              :columns="[
                {name:'ean13',header:'|||||||||',help:'Código de barras del artículo'},
                {name:'articulo_id',header:'Artículo', type: 'm2o', primary:'codigo', label:'codigo', noOpen: true, fields:[{name:'thumbnail',fields:['imagen']},'tarifa1','tarifa2','tarifa3','tarifa4','tarifa5','tarifa6','tarifa7','tarifa8','tarifa9','tarifa10']},
                {name:'descripcion',header:'Descripción'},
                {name:'modelo_id',header:'Modelo', type:'m2o', primary:'codigo', label:'codigo', readOnly:true},
                //{name:'familia_id',header:'Familia', type:'m2o', primary:'codigo', label:'codigo', readOnly:true},
                {name:'nota',header:'Nota'},
                {name:'tt',header:'T', type:'select', options:['A','R']},
                {name:'almacen_id',header:'Almacén', type:'m2o', primary:'codigo', readOnly: true},
                {name:'unidades',header:'Unid.',type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'etiqueta',header:'Etiq.', type: 'numeric'},
                {name:'peso',header:'Peso',type:'numeric',numericFormat: { pattern: '0,0.000'}},
                {name:'taqueria_ids',header:'Taquería',type:'m2m',primary:'codigo', image: 'thumbnail.imagen',fields:['precio_coste'], label: 'codigo', filter: ()=>['seccion_id','=','PCK']},
                {name:'taqueria_precio',header:'Taq.(€)',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
                {name:'v',header:'V', type:'select', options:['U','P']},
                {name:'m',header:'M', type:'checkbox'},
                {name:'r',header:'R', type:'checkbox'},
                {name:'precio_coste',header:'P. Coste',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
                {name:'tt2',header:'T', type:'select', options:['1','2','3','4','5','6','7','8','9','10']},
                {name:'precio',header:'Precio',type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
                {name:'descuentos',header:'Dtos.',type:'numeric',numericFormat: { pattern: '0,0.00'}, sufix: '%'},
                {name:'descuento_peso',header:'Dto./peso',type:'numeric',numericFormat: { pattern: '0,0.00'}, sufix: '%'},
                {name:'totallinea',header:'Total Línea',type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'m2',header:'M', type:'checkbox'},
                {name:'c2',header:'C', type:'checkbox'},
                {name:'p',header:'P', type:'checkbox'},
                {name:'u',header:'U', type:'checkbox'},
                {name:'com',header:'Com.', type:'m2o', label:'codigo', primary:'codigo', emptyOnError: true},
                {name:'c',header:'C.', type:'select', options:['D','I']},
                {name:'margen',header:'%',type:'numeric',numericFormat: { pattern: '0,0.00'}, sufix: '%'},
                {name:'pventa',header:'PVP',type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
                {name:'e',header:'E',type:'checkbox'},
              ]"
            />
          </div>
        </r-tabs>
        <!-- tabs -->
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import VentaMixin from "./../components/VentaMixin.js";
import FacturaMixin from "./../components/FacturaMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin, FacturaMixin, VentaMixin],
  data: function() {
    var self = this;
    return {
      title: "Mantenimiento Devolución de condicionales de venta",
      dbAdapter: "devolucion_condicional_venta",
      primary: "ndoc",
      defaultDataSearch: {
        wt: 0
      },
      defaultData: {
        wt: 0,
        fecha: new Date().yyyymmdd()
      },
      sequence: {
        name: function() {
          return (
            "devolucion_condicional_compra_" + (self.formData.wt ? "1" : "0")
          );
        },
        params: ["wt"]
      }
    };
  },
  mounted() {
    var self = this;
    self.$on("loadItem", function() {
      self.actualizar_total_unidades();
      self.actualizar_total_peso();
      self.actualizar_total_articulos();
    });
  }
};
</script>